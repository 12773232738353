@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Exo 2", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  cursor: pointer;
}

.link-img{
  display: none;
}

.link:hover{
  .link-img{
    display: block;
  }
}

.headlink-sub{
  display: none;
}

.headlink:hover{
  .headlink-sub{
    display: flex;
  }
}

.dev-card:hover{
  h1{
    color:#FEFEFE;
  }
  a{
    color: #20EB65;
  }
  #arr2{
    display: block;
  }
  #arr1{
    display: none;
  }
}

canvas{
  background: transparent;
  width: 100%;
  height: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #20EB65;
}

.hovering{
  transition: font-size 0.3s ease; /* Плавное изменение размера шрифта */
}

.hovering:hover {
  font-size: 20px; /* Размер шрифта при наведении */
}